<template>
  <div v-if="visibility" id="ucModal">
    <div class="uc-modal-backdrop" @mousedown.self="hide(BACKDROP_TRIGGER)">
      <div
        class="uc-modal"
        role="dialog"
        :class="{
          'uc-modal--full': fullScreen,
          'uc-modal--active': animated,
          'uc-modal-overflow-visible': showOverflow
        }"
      >
        <div class="uc-modal-banner">
          <slot name="banner" />
        </div>
        <div class="uc-modal-content" :class="modalContentClasses">
          <slot :hide="hide" />
        </div>
        <img
          v-if="closeButton"
          class="uc-modal-close"
          :src="`${BASE_IMG_URL}/assets/svg/modal-close.svg`"
          alt="close"
          @click="hide(CLOSE_BUTTON_TRIGGER)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export const CLOSE_BUTTON_TRIGGER = 'close-button';
export const BACKDROP_TRIGGER = 'backdrop';

export default {
  name: 'UcModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    showOverflow: {
      type: Boolean,
      default: false
    },
    toggleScroll: {
      type: Boolean,
      default: true
    },
    modalContentClasses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visibility: false,
      animated: false
    };
  },
  computed: {
    CLOSE_BUTTON_TRIGGER: () => CLOSE_BUTTON_TRIGGER,
    BACKDROP_TRIGGER: () => BACKDROP_TRIGGER,
    BASE_IMG_URL: () => process.env.BASE_IMG_URL
  },
  watch: {
    visibility(isOpen) {
      this.holdScroll(isOpen);
      this.$emit('input', isOpen);
    },
    value: {
      handler() {
        this.visibility = this.value;
        setTimeout(() => (this.animated = this.value));
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.holdScroll(false);
    this.hide();
  },
  methods: {
    holdScroll(bool) {
      if (this.toggleScroll) {
        this.$eventBus.$emit('general:body:hold-scroll', bool);
      }
    },
    hide(trigger) {
      this.visibility = false;
      if (trigger) {
        this.$emit('hide-trigger', trigger);
      }
    }
  }
};
</script>
